

.directory-card{
  .directory-bg{
    background-image: url("https://img.freepik.com/premium-photo/global-business-background_670147-18977.jpg");
    background-size: cover;
    background-position: center center;
    height: 80px;
    border-radius: 4px;

  }
  .directory-overlay{
    background-color: rgba(var(--bs-primary-rgb), 0.7);
    padding: 20px;
    height: 80px;
    border-radius: 4px;

  }
  .profile-pic{
    position: relative;
    top: 1px;
  }

}


.directory-card{
    height: 400px;
    max-height: 400px;
    min-width: 550px;
    width: 270px;
    max-width: 270px;
    min-width: 270px;
    margin: 11px;
    padding: 1px;
    border-radius: 8px;

}



.btn-sm {
    padding: 0.25rem 0.5rem;
    font-size: 0.875rem;
}

.me-1 {
    margin-right: 0.25rem;
}

.text-center {
    text-align: center;
}

.text-start {
    text-align: left;
}

/* Animations ViewEmployees.css */

.fade-slide-enter {
  opacity: 0;
  transform: translateX(-100%);
}

.fade-slide-enter-active {
  opacity: 1;
  transform: translateX(0);
  transition: opacity 500ms, transform 500ms;
}

.fade-slide-exit {
  opacity: 1;
  transform: translateX(0);
}

.fade-slide-exit-active {
  opacity: 0;
  transform: translateX(100%);
  transition: opacity 500ms, transform 500ms;
}
.flip-enter {
  transform: rotateY(90deg);
  opacity: 0;
}
.flip-enter-active {
  transform: rotateY(0);
  opacity: 1;
  transition: transform 500ms, opacity 500ms;
}
.flip-exit {
  transform: rotateY(0);
  opacity: 1;
}
.flip-exit-active {
  transform: rotateY(90deg);
  opacity: 0;
  transition: transform 500ms, opacity 500ms;
}
