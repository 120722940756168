

.directory-card{
  .directory-bg{
    background-image: url("https://img.freepik.com/premium-photo/global-business-background_670147-18977.jpg");
    background-size: cover;
    background-position: center center;
    height: 80px;
    border-radius: 4px;

  }
  .directory-overlay{
    background-color: rgba(var(--bs-primary-rgb), 0.7);
    padding: 20px;
    height: 80px;
    border-radius: 4px;

  }
  .profile-pic{
    position: relative;
    top: 1px;
  }

}


.directory-card{
    height: 380px;
    max-height: 380px;
    min-width: 550px;
    width: 270px;
    max-width: 270px;
    min-width: 270px;
    margin: 11px;
    padding: 1px;
    border-radius: 8px;

}

tbody>tr{
    padding:0%;
}



.table {
    width: 100%;
}

.table td {
    padding: 4px;
}

.btn-sm {
    padding: 0.25rem 0.5rem;
    font-size: 0.875rem;
}

.me-1 {
    margin-right: 0.25rem;
}

.text-center {
    text-align: center;
}

.text-start {
    text-align: left;
}

