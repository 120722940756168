.AppsList{
    height: 90px;
    width: 90px;
    min-width: 90px;
    min-height: 90px;
    margin: 1px;
  }
  .app-list-area::-webkit-scrollbar {
    width: 20px;
}
  .app-list-area::-webkit-scrollbar-track {
    background-color: transparent;
  }
  .app-list-area::-webkit-scrollbar-thumb {
    background-color: #d6dee1;
    border-radius: 10px;
    border: 6px solid transparent;
    background-clip: content-box;
  }
  .AppsList-icon{
    border: rgba(63, 72, 74, 0.832) solid 2px;
    height: 90px;
    width: 90px;
  }
  .AppsList-icon:hover{
    border: rgb(0, 127, 253) solid 1px;
    /* giving a smooth transition */
    transition: border 0.4s linear;
  }
  .app-list-area{
    height: 200px;
    overflow-y: scroll;

  }



  